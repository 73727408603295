/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import '../CSS/Principal.css';

function Principal() {
  const rol = Cookies.get("rol");
  const isDisabled = rol !== "00-ADMIN";
  const handleClickGP = (e) => {
    if (isDisabled) {
      e.preventDefault();
      Swal.fire({
        icon: 'error',
        title: 'USUARIO NO AUTORIZDO',
        text: 'El usuario no está autorizado para ingresar a esta dirección',
      });
    } else {
      window.location.href = 'gestion-pagos';
    }
  }
  
  useEffect(() => {
    const rol = Cookies.get('rol');
    if (rol === '5') {
      document.getElementById('placaBtn').style.display = 'none';
      document.getElementById('asistenciaQRBtn').style.display = 'none';
      document.getElementById('registrousuariobtn').style.display = 'none';
    } else {
    }
  }, []);
  const handleLogout = () => {
    Cookies.remove("rol");
    Cookies.remove("nombre");
    Cookies.remove("apellidopat");
    Cookies.remove("apellidomat");
    Cookies.remove("dnilogin");
    Cookies.remove("miip");
    Cookies.remove("codigorol");
    Cookies.remove("idrol");
    Cookies.remove("horario");
    window.location.replace('/');
  };
  return (
    <div>
      <header style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img src={require("../../componentes/logoerlan.png")} style={{ width: '15%', height: '80px', objectFit: 'cover' }} className='logoerlan' />
      </header>
    <nav>
      <a className="salir" onClick={handleLogout} style={{cursor:'pointer'}}><i className="fa-solid fa-users-slash"></i> Cerrar Sesión</a>
    </nav>
      <div className='pokemon-todos'>
        <a href='personalseguridad' className='botonesprincipal'>
        <div className='pokemon2'>
              <div className="row">
                <div className="col-6 pokemon-info">
                  <div className="nombre-contenedor">
                    <h2 className="pokemon-nombre">Personal de Seguridad</h2>
                  </div>
                  <div className="pokemon-tipos">
                    Personal de Seguridad Registrado
                  </div>
                </div>
                <div className="col-6 pokemon-imagen">
                  <img src={require("../../componentes/personalseguridad.png")} className="scanearqr" />
                </div>
              </div>
            </div>
        </a>
        <a  style={{border:'none'}}  onClick={handleClickGP} className='botonesprincipal'>
        <div className='pokemon'>
              <div className="row">
                <div className="col-6 pokemon-info">
                  <div className="nombre-contenedor">
                    <p className="pokemon-id"></p>
                    <h2 className="pokemon-nombre">Gestión de Pagos </h2>
                  </div>
                  <div className="pokemon-tipos" >
                    Gestiona tus pagos
                  </div>
                </div>
                <div className="col-6 pokemon-imagen">
                  <img src={require("../../componentes/icono_gestion.png")} className="scanearqr" style={{minWidth:'80%'}}/>
                </div>
              </div>
            </div>
        </a>
      </div>
    </div>
  )
}
export default Principal